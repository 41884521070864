import { EnvironmentTypes } from '@environments/environment-types.enum';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  type: EnvironmentTypes.DEVELOP,
  production: true,
  api: {
    baseUrl: '/api',
    useTranslations: true
  },
  assets: {
    imagePath: '/assets/img/'
  },
  errorPages: {
    debug: true
  },
  logger: {
    level: NgxLoggerLevel.DEBUG
  },
  dealer: {
    oAuth2: {
      encryptionKey: '7AZD,EAw849oSQ26f+utI+Hn72j4p7a6'
    }
  },
  cookiebot: {
    id: '8cc0ea74-263a-441c-858f-bd3be133ff5e'
  },
  showOrderPeriodStartedPopup: false,
  showJoinPartyPage: false,
  hideSalutationCompany: true,
  salutationUnknownAsDefault: true
};
